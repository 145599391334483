import NextApp, { AppProps } from 'next/app';
import Head from 'next/head';
import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { grayscale } from 'polished';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../theme';

const Meta: FC = () => {
  return (
    <Head>
      <title>NextJS TypeScript Template</title>

      <link href="/static/manifest.json" rel="manifest" />
    </Head>
  );
};

const GlobalStyle = createGlobalStyle`
  &:root {
    --edge-weight-positive-color: #3CB327;
    --edge-weight-neutral-color: ${grayscale('#FF301E')};
    --edge-weight-negative-color: #FF301E;
  }
`;

const Main = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Meta />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
};

export default class App extends NextApp {
  render() {
    return <Main {...this.props} />;
  }
}
